<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    style="overflow-y: hidden;"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="require(`@/assets/background/sidebar/${barImage}`)"
    mobile-breakpoint="960"
    app
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <template
      nav
      expand
    >
      <v-img
        src="@/assets/vincss-logo-text-white.png"
        aspect-ratio="3"
        contain
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      expand
      nav
    >
      <base-item-group
        :key="profile.title"
        :item="profile"
      />
    </v-list>

    <v-divider class="mb-1" />

    <v-list
      expand
      nav
    >
      <base-item
        :key="dashboard.title"
        :item="dashboard"
      />

      <v-spacer />

      <strong class="mb-3 d-inline-block">{{ !expandOnHover ? $t('stats') : 'STATS' }}</strong>
      <v-spacer />
      <template v-for="item in stats">
        <base-item
          :key="item.title"
          :item="item"
        />
      </template>

      <strong class="mb-3 d-inline-block">{{ !expandOnHover ? $t('configs') : 'CONFIG' }}</strong>
      <v-spacer />
      <template v-for="item in configs">
        <base-item
          :key="item.title"
          :item="item"
        />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'
  // import LocalStorageService from '../../../../utils/auth'

  // const localStorageService = LocalStorageService.getService()

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      dashboard: {
        icon: 'mdi-view-dashboard',
        title: 'Dashboard',
        to: '/',
      },
      users: {
        icon: 'mdi-account-supervisor',
        title: 'Users',
        to: '/users',
      },
      stats: [
        {
          icon: 'mdi-apps',
          title: 'Apps',
          to: '/stats/apps',
        },
        {
          icon: 'mdi-fingerprint',
          title: 'Keys',
          to: '/stats/keys',
        },
      ],
      configs: [
        {
          icon: 'mdi-apps',
          title: 'Apps',
          to: '/configs/apps',
        },
        {
          icon: 'mdi-account-multiple',
          title: 'Users',
          to: '/configs/users',
        },
        {
          icon: 'mdi-shield-check',
          title: 'Permission',
          to: '/configs/permission',
        },
      ],
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },

      profile () {
        return {
          group: '/master',
          avatar: true,
          title: this.$store.getters.currentUser.fullName,
          children: [
            {
              title: 'Profile',
              to: 'profile',
            },
            {
              title: 'Keys',
              to: 'keys',
            },
          ],
        }
      },
    },

    watch: {
      '$vuetify.breakpoint.smAndDown' (val) {
        this.$emit('update:expandOnHover', !val)
      },
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    &.v-navigation-drawer--mini-variant
      .v-list-item
        justify-content: flex-start !important

      .v-list-group--sub-group
        display: block !important

    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
